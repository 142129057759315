import { useRouter } from "next/router";
import * as cx from "classnames/bind";
import s from "styles/admin.module.scss";
import { useState } from "react";
import Link from "next/link";
import { NavLink, Icon, UserMenu } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSession, signOut } from "next-auth/react";
import { useTranslation } from "next-i18next";

const HorecaMenu = ({
  label,
  className,
  children,
  user,
  organization,
  hotel,
  bar,
  ...other
}) => {
  const router = useRouter();
  const { data: session, status } = useSession();
  const { t } = useTranslation();

  const [active, setActive] = useState(false);

  const logOut = () => {
    signOut("credentials", { redirect: false });
    router.push("/horeca/login");
  };

  const handleClick = () => {
    setActive(!active);
  };
  const isActive = (path, exact = false) => {
    return exact ? router.pathname === path : router.pathname.includes(path);
  };

  let basePath = "/horeca/";

  basePath += [
    [organization || bar, ""],
    [organization && hotel, "hotels"],
    [organization && bar, "bars"],
  ]
    .filter(([o, _]) => !!o)
    .map(([o, suffix]) => (suffix ? `${suffix}/${o.slug}` : o.slug))
    .join("/");

  return (
    <div
      className={cx(
        "sideMenu",
        s.sideMenu,
        user?.barTender || user?.barManager ? "sideMenu__Bartender" : "",
      )}
    >
      <div
        className={cx("menuTrigger", s.menuTrigger, active ? "" : s.closed)}
        onClick={handleClick}
      >
        <span />
      </div>
      <Link
        href="/horeca"
        className={cx(s.logoHolder, s.logoHolder__horeca)}
        style={{ display: "block" }}
      >
        <picture>
          <source
            media="(max-width: 768px)"
            srcSet={`/img/logos/${organization?.slug}.png`}
          />
          <source
            media="(min-width: 769px)"
            srcSet={`/img/logos/${organization?.slug}.png`}
          />
          <img
            alt={organization?.slug}
            src={`/img/logos/${organization?.slug}.png`}
          />
        </picture>
      </Link>
      <div className={cx(s.menuHolder, active ? "" : s.closed)}>
        <ul className={cx("mainMenu", s.mainMenu)}>
          {(user?.barTender || user?.barManager) && (
            <li className={cx(isActive("/my-bar") ? s.active : "")}>
              <NavLink href={`/horeca`}>
                <a onClick={handleClick}>
                  <Icon size="far" svg="wine-bottle" />
                  My bar
                </a>
              </NavLink>
            </li>
          )}
          {!user?.barTender && (
            <>
              {!user?.barTender && !user?.barManager && (
                <li className={isActive("/horeca/", true) ? s.active : ""}>
                  <NavLink href="/horeca/">
                    <a>
                      <Icon size="far" svg="sitemap" />
                      Organization
                    </a>
                  </NavLink>
                  <ul className={cx("subMenu", s.subMenu)}>
                    <li className={isActive("/horeca/", true) ? s.active : ""}>
                      <NavLink href="/horeca/">
                        <a onClick={handleClick}>Overview</a>
                      </NavLink>
                    </li>
                  </ul>
                </li>
              )}
              {organization?.hotels?.length > 0 &&
                !user?.barTender &&
                !user?.barManager && (
                  <>
                    <li
                      className={
                        isActive(`/horeca/${organization?.slug}/hotels`, true)
                          ? s.active
                          : ""
                      }
                    >
                      <NavLink href={`/horeca/${organization?.slug}/hotels`}>
                        <a>
                          <Icon size="far" svg="hotel" />
                          Hotels
                        </a>
                      </NavLink>
                      <ul className={cx("subMenu", s.subMenu)}>
                        <li
                          className={
                            isActive(
                              `/horeca/${organization?.slug}/hotels`,
                              true,
                            )
                              ? s.active
                              : ""
                          }
                        >
                          <NavLink
                            href={`/horeca/${organization?.slug}/hotels`}
                          >
                            <a onClick={handleClick}>All hotels</a>
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                  </>
                )}
              {!user?.barTender && !user?.barManager && (
                <li
                  className={
                    isActive(`/horeca/${organization?.slug}/bars`, true)
                      ? s.active
                      : ""
                  }
                >
                  <NavLink href={`/horeca/${organization?.slug}/bars`}>
                    <a>
                      <Icon size="far" svg="wine-bottle" />
                      Bars
                    </a>
                  </NavLink>
                  <ul className={cx("subMenu", s.subMenu)}>
                    <li
                      className={
                        isActive(`/horeca/${organization?.slug}/bars`, true)
                          ? s.active
                          : ""
                      }
                    >
                      <NavLink href={`/horeca/${organization?.slug}/bars`}>
                        <a onClick={handleClick}>All bars</a>
                      </NavLink>
                    </li>
                    {user &&
                      user.tended_bars.map((bar) => {
                        return (
                          <li onClick={handleClick} key={bar.id}>
                            <NavLink href={bar.url}>
                              <a>{bar.name}</a>
                            </NavLink>
                          </li>
                        );
                      })}
                  </ul>
                </li>
              )}
            </>
          )}
          <li className={isActive("/cocktails") ? s.active : ""}>
            <NavLink href={`${basePath}/cocktails`}>
              <a onClick={handleClick}>
                <Icon size="far" svg="cocktail" />
                Cocktails
              </a>
            </NavLink>
            <ul className={cx("subMenu", s.subMenu)}>
              {!user?.barTender ? (
                <>
                  <li>
                    <NavLink href={`${basePath}/cocktails`}>
                      <a onClick={handleClick}>Your cocktails</a>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink href={`${basePath}/cocktails/find`}>
                      <a onClick={handleClick}>Recipe database</a>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      href={`${basePath}/cocktails/create`}
                      prefetch={false}
                    >
                      <a onClick={handleClick}>Create new cocktail</a>
                    </NavLink>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <NavLink href={`${basePath}/cocktails`}>
                      <a onClick={handleClick}>Your cocktails</a>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink href={`${basePath}/cocktails/find`}>
                      <a onClick={handleClick}>Explore cocktails</a>
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </li>

          <li className={isActive("/menus") ? s.active : ""}>
            <NavLink href={`${basePath}/menus`}>
              <a>
                <Icon size="far" svg="memo" />
                Menus
              </a>
            </NavLink>
            <ul className={cx("subMenu", s.subMenu)}>
              <li>
                <NavLink href={`${basePath}/menus`}>
                  <a onClick={handleClick}>All menus</a>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className={isActive("/checklists") ? s.active : ""}>
            <NavLink href={`${basePath}/checklists`}>
              <a>
                <Icon size="far" svg="list-check" />
                Checklists
              </a>
            </NavLink>
            <ul className={cx("subMenu", s.subMenu)}>
              <li>
                <NavLink href={`${basePath}/checklists`}>
                  <a onClick={handleClick}>All checklists</a>
                </NavLink>
              </li>
              {!user?.barTender && (
                <li>
                  <NavLink href={`${basePath}/checklists/create`}>
                    <a onClick={handleClick}>Create checklist</a>
                  </NavLink>
                </li>
              )}
            </ul>
          </li>
          <li className={cx(isActive("/articles") ? s.active : "")}>
            <NavLink href={`${basePath}/articles`}>
              <a onClick={handleClick}>
                <Icon size="far" svg="memo" />
                Articles
              </a>
            </NavLink>
          </li>
          {!user?.barTender && (
            <li className={cx(isActive("/users") ? s.active : "")}>
              <NavLink href={`/horeca/${organization?.slug}/users`}>
                <a onClick={handleClick}>
                  <Icon size="far" svg="user-group" />
                  Users
                </a>
              </NavLink>
            </li>
          )}
          <li className="hidden" disabled>
            <NavLink href="/admin/analytics">
              <a target="_blank" onClick={handleClick}>
                <Icon size="far" svg="chart-simple" />
                Analytics
              </a>
            </NavLink>
          </li>
        </ul>
        <div className={cx("UserMenu", s.UserMenu)}>
          <a>{session?.user.email}</a>
          <a onClick={logOut}>
            <Icon size="far" svg="power-off" />
            {t("Log out")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default HorecaMenu;
